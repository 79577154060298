import axios from 'axios'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const HrLogin = () => {

    const [formDetails, setFormDetails] = useState({
        email: '',
        password: ''
    })

    const navigate = useNavigate()

    const handelFormSubmission = async (e) => {
        e.preventDefault()

        await axios.post('http://localhost:4000/hrvalidation', formDetails)
            .then(response => {
                if (response.data.status) {
                    window.localStorage.setItem("hr", formDetails.email)
                    navigate('/hrcontrols')
                }
                else {
                    toast.error(response.data.msg)
                }
            })
            .catch(err => {
                toast.error('Error')
            })
    }

    return (
        <div className='px-[440px] mt-10 flex justify-center items-center'>
            <div className='mt-36'>
                <img src='/Images/HrLogin/team_work.svg' className='h-80 w-80' />
            </div>

            <div>
                <form className='mt-44' onSubmit={handelFormSubmission}>
                    <h1 className='text-center mb-5 text-blue-300'>Hr Login</h1>
                    <div className='flex flex-col'>
                        <input className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4' type='email' placeholder='Enter your email' onChange={(e) => setFormDetails({ ...formDetails, email: e.target.value })} />
                        <input className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4' type='password' placeholder='Enter your password' onChange={(e) => setFormDetails({ ...formDetails, password: e.target.value })} />
                        <button className='border-2 border-blue-200 bg-blue-200 rounded-lg p-2 text-white' type='submit'>Login</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default HrLogin