import React, { useState } from 'react'
import { AiOutlinePlus } from "react-icons/ai";
import NewsCard from '../newsPage/NewsCard';
import { Modal } from 'antd';
import axios from 'axios';
import toast from 'react-hot-toast';
import { CiEdit } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";

const NewsSection = ({ news, setFlag }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [editNewsId, setNewsId] = useState("")
    const [image, setImage] = useState(null)
    const [formDetails, setFormDetails] = useState({
        headline: '',
        content: '',
        imageUrl: '',
        embedVideoUrl: ''
    })
    const [editFormDetails, setEditFormDetails] = useState({
        headline: '',
        content: '',
        imageUrl: '',
        embedVideoUrl: ''
    })

    const [formData, setFormData] = useState(null)

    const handleFileChange = (e) => {
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        formData.append("upload_preset", "rap0jfwa")
        setImage(e.target.files[0])
        setFormData(formData)
    }

    const handelFormSubmission = async (e) => {
        e.preventDefault()
        let uploadedUrl = ""
        if (image) {
            // have to call api to upload image and get the url.
            await axios.post(`https://api.cloudinary.com/v1_1/dipdggpwh/image/upload`, formData)
                .then(response => {
                    const match = response.data.url.match(/\/v([\w-]+\/[\w-]+)/)
                    uploadedUrl = match[0]
                })
                .catch(error => toast.error(error))
            }

        await axios.post('http://localhost:4000/news/create', { ...formDetails, imageUrl: uploadedUrl ? uploadedUrl : "" })
        .then(response => {
            if(response.data.status === true) {
                toast.success(response.data.msg)
                setIsModalOpen(false)
                setFlag(prev => !prev)
            }
        })
        .catch(error => {
            if (error.response.data.msg === 'News creation failed' && error.response.data.status === false)
                toast.error(error.response.data.msg)
            else if (Array.isArray(error.response.data.msg)) {
                error.response.data.msg.map(error => (
                    toast.error(error)
                ))
            }
        })
    }


    const handelEditFormSubmission = async (e) => {
        e.preventDefault()
        let uploadedUrl = ""
        if (image) {
            // have to call api to upload image and get the url.
            await axios.post(`https://api.cloudinary.com/v1_1/dipdggpwh/image/upload`, formData)
                .then(response => {
                    const match = response.data.url.match(/\/v([\w-]+\/[\w-]+)/)
                    uploadedUrl = match[0]
                })
                .catch(error => toast.error(error))
        }

        await axios.patch('http://localhost:4000/news/edit', { ...editFormDetails, imageUrl: uploadedUrl ? uploadedUrl : editFormDetails.imageUrl, editNewsId })
        .then(response => {
            if(response.data.status === true) {
                toast.success(response.data.msg)
                setIsEditModalOpen(false)
                setFlag(prev => !prev)
            }
        })
        .catch(error => {
            if (error.response.data.msg === 'Edit failed' && error.response.data.status === false)
                toast.error(error.response.data.msg)
            else if (Array.isArray(error.response.data.msg)) {
                error.response.data.msg.map(error => (
                    toast.error(error)
                ))
            }
            setIsEditModalOpen(false)
        })
    }

    const getSingleNewsDetails = async (newsId) => {
        setNewsId(newsId)
        await axios.post('http://localhost:4000/news/getsinglenews', { newsId })
        .then(response => {
            if(response.data.status) {
                setEditFormDetails({
                    headline: response.data.msg.headline,
                    content: response.data.msg.content,
                    imageUrl: response.data.msg.imageUrl,
                    embedVideoUrl: response.data.msg.embedVideoUrl
                })
                setIsEditModalOpen(true)
            }
        })
        .catch(error => {
            toast.error(error.response.data.msg)
        })
    }


    const handleDeleteNews = async (newsId) => {
        await axios.delete('http://localhost:4000/news/delete', { data: { newsId } })
        .then(response => {
            if(response.data.status) {
                toast.success(response.data.msg)
                setFlag(prev => !prev)
            }
        })
        .catch(error => {
            toast.error(error.response.data.msg)
        })
    }

    const handleClickLinkedin = () => {
        window.open("https://www.linkedin.com/company/transvolt-mobility-pvt-ltd/", "_blank");
    };
    return (
        <div className='mt-20 px-32'>

            <div className='mb-20'>
                <button onClick={() => setIsModalOpen(true)} className='flex items-center border-blue-200 bg-blue-200 text-white p-2 rounded-xl'><AiOutlinePlus />Create News</button>
            </div>

            {
                news.map(obj => (
                    <div key={obj._id} className='flex mb-16'>
                        <NewsCard key={obj._id} item={obj} handleClickLinkedin={handleClickLinkedin} />
                        <CiEdit className='cursor-pointer text-3xl text-blue-500' onClick={() => getSingleNewsDetails(obj._id)}/>
                        <AiOutlineDelete onClick={() => handleDeleteNews(obj._id)} className='cursor-pointer text-2xl text-red-500'/>
                    </div>
                ))
            }


            <>
                <Modal title="Create News" open={isModalOpen} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
                    <form className='mt-12' onSubmit={handelFormSubmission}>
                        <div className='flex flex-col'>
                            <input className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' type='text' placeholder='Enter your headline' onChange={(e) => setFormDetails({ ...formDetails, headline: e.target.value })} />
                            <textarea className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' placeholder='Enter your content' onChange={(e) => setFormDetails({ ...formDetails, content: e.target.value })} />
                            <div className='mb-3 border-2 border-[#f5f5fa] bg-[#f5f5fa] cursor-pointer rounded-3xl flex justify-center w-64'>
                                <label htmlFor="fileInput">
                                    <img alt='newsimage' src={image ? URL.createObjectURL(image) : 'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'} className='h-64 w-64 rounded-3xl cursor-pointer' />
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className='hidden'
                                        onChange={handleFileChange}
                                    />
                                </label>
                            </div>
                            <input className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' placeholder='Paste the embed link here' type='text' onChange={(e) => setFormDetails({ ...formDetails, embedVideoUrl: e.target.value }) } />
                            <button className='border-2 border-blue-300 bg-blue-300 rounded-lg p-2 text-white' type='submit'>Create</button>
                        </div>
                    </form>
                </Modal>
            </>


            <>
                <Modal title="Edit News" open={isEditModalOpen} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
                    <form className='mt-12' onSubmit={handelEditFormSubmission}>
                        <div className='flex flex-col'>
                            <input defaultValue={editFormDetails.headline} className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' type='text' placeholder='Enter your headline' onChange={(e) => setEditFormDetails({ ...editFormDetails, headline: e.target.value })} />
                            <textarea defaultValue={editFormDetails.content} className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' placeholder='Enter your content' onChange={(e) => setEditFormDetails({ ...editFormDetails, content: e.target.value })} />
                            <div className='mb-3 border-2 border-[#f5f5fa] bg-[#f5f5fa] cursor-pointer rounded-3xl flex justify-center w-64'>
                                <label htmlFor="fileInput">
                                    <img alt='newsimage' src={image ? URL.createObjectURL(image) : editFormDetails.imageUrl ? `https://res.cloudinary.com/dipdggpwh/image/upload${editFormDetails.imageUrl}.png` : 'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'} className='h-64 w-64 rounded-3xl cursor-pointer' />
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className='hidden'
                                        onChange={handleFileChange}
                                    />
                                </label>
                            </div>
                            <input defaultValue={editFormDetails.embedVideoUrl} className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' placeholder='Paste the embed link here' type='text' onChange={(e) => setEditFormDetails({ ...editFormDetails, embedVideoUrl: e.target.value })} />
                            <button className='border-2 border-blue-300 bg-blue-300 rounded-lg p-2 text-white' type='submit'>Edit</button>
                        </div>
                    </form>
                </Modal>
            </>
        </div>
    )
}

export default NewsSection