import React, { useState, useEffect } from 'react';
import './MainContent.css';

const imageArray = [
    './Images/HomePage/1.jpg',
    './Images/HomePage/2.jpg',
    './Images/HomePage/3.jpg',
    './Images/HomePage/4.jpg',
  ];
const AutoSlider = ({ interval }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex(prevIndex =>
        prevIndex === imageArray.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    // Clear the interval on unmount
    return () => clearInterval(timer);
  }, [interval]);

  return (
    <div className="auto-slider">
      <img src={imageArray[currentImageIndex]} alt={`Slide ${currentImageIndex}`} />
    </div>
  );
};

export default AutoSlider;
