import React from 'react'

const NewsCard = ({ item, handleClickLinkedin }) => {
    return (
        <>
            <div className='relative flex h-[50%] gap-x-7 items-center'>
                <img src={!item.imageUrl.includes('/Images') ? `https://res.cloudinary.com/dipdggpwh/image/upload${item.imageUrl}.png` : item.imageUrl} alt={item.altText} className="news-image" />
                <img className='arrow-image' src='/Images/NewsPage/Arrow-Color-Transparent.svg' alt="Arrow" />
                <h4 className='text-[#25509D]'>{item.headline}</h4>
                {/* Updated button with onClick handler */}
                <button className="visit-button" onClick={handleClickLinkedin}>Read</button>
            </div>
            <div className="mt-7">
                <p>{item.content}</p>
            </div>
        </>
    )
}

export default NewsCard