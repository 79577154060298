import React from 'react';
import './servicesPages.css'; // Make sure to create a corresponding CSS file
import Header from '../header/Header';
import Navbar from '../header/Navbar';
import img1 from '../Images/ServicesPage/BannerVector.png';
import img2 from '../Images/ServicesPage/Bus_Image_Peralax.png'
import imgLogo from '../Images/ServicesPage/ArrowColor.svg';
import serviceImg from '../Images/ServicesPage/serviceImg.jpeg';
import openIcon from '../Images/ServicesPage/openIcon.svg';
import leftIcon from '../Images/ServicesPage/leftIcon.svg';
import rightIcon from '../Images/ServicesPage/rightIcon.svg';
import leftIconDark from '../Images/ServicesPage/leftIconDark.svg';
import rightIconDark from '../Images/ServicesPage/rightIconDark.svg';
import closeButton from '../Images/ServicesPage/closeButton.svg';
import breadcrumb from '../Images/ServicesPage/breadcrumb.svg';
import Footer from '../footer/Footer';

const HeaderCTA = () => (
  <div className="bg-[#EEF] py-10">
    <div className='flex text-left items-center justify-between px-40'>
      <div className='text-[#25509D] text-sm md:text-lg w-[70%] md:w-[40%] lg:w-[20%] font-semibold' style={{ fontWeight: "600", lineHeight: "23px" }}>
        <p>We want to empower the freedom to move in a clean, green and quite environment with safety.</p>
      </div>

      <div className='text-[#585858] text-sm md:text-lg w-[90%] md:w-[60%]' style={{ fontWeight: "400", lineHeight: "23px" }}>
        <h7>Transvolt works towards development & operation of an integrated and connected ecosystem wherein everyone access to clean, green, and quite transportation solutions in the B2G, B2C and B2B domains. We would like to make the entire ecosystem sustainable and accessible for all users – children, pregnant women, senior citizens, specially-abled friends.</h7>
      </div>
    </div>

    <div className='text-center text-base md:text-xl pt-10 leading-6 md:leading-10' style={{ fontWeight: "600" }}>
      <p>We want to empower the freedom to move in a clean, green and quite environment with safety.</p>
    </div>
  </div>
);

const TitleSection = () => (
    <div >
      <div className='bg-gradient-to-r from-[#548235] to-[#4472c4] p-3'>
        <h1 className='text-center text-white leading-6 text-sm md:text-3xl'>TRANSVOLT: ZERO EMISSION PLATFORM</h1>
      </div>
      <div className='busimage'></div>
    </div>

);

const VehicleCard = ({ imageSrc, title, subtitle }) => (
  <div className="vehicle-card">
    <img src={imageSrc} alt={title} />
    <h2>{title}</h2>
    <p>{subtitle}</p>
  </div>
);

const VehicleGrid = () => {
  const vehicles = [
    {
      name: 'Intracity / Intercity Bus',
      description: 'City Authorities / State / Central Govt. / B to C / B to B',
      imageSrc: require('../Images/ServicesPage/Image_Viewer-Bus1.png'),
      imageSrc2: require('../Images/ServicesPage/1.png'),
    },
    {
      name: 'Truck',
      description: 'Large Cargo',
      imageSrc: require('../Images/ServicesPage/Image_Viewer-Truck.png'),
      imageSrc2: require('../Images/ServicesPage/3.png'),
    },
    {
      name: 'Small Commercial Vehicle',
      description: 'First and Last Mile Delivery',
      imageSrc: require('../Images/ServicesPage/Image_Viewer-SCV.png'),
      imageSrc2: require('../Images/ServicesPage/4.png'),
    },
    {
      name: 'Garbage Truck ',
      description: 'Urban Local Bodies ',
      imageSrc: require('../Images/ServicesPage/Image_Viewer-Garbage.png'),
      imageSrc2: require('../Images/ServicesPage/7.png'),
    },
    {
      name: 'Car',
      description: 'Across Cities  ',
      imageSrc: require('../Images/ServicesPage/Image_Viewer-Car.png'),
      imageSrc2: require('../Images/ServicesPage/8.png'),
    },

  ];
  const [viewerOpen, setViewerOpen] = React.useState(false);
  return (
      <section className="vehicles" style={{ width: '100%', padding: '30px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
        {vehicles.map(vehicle => (
          <div key={vehicle.name} className="flex flex-col md:flex-row justify-between p-10 border-2 border-[#ccc] rounded-lg" >
            <div style={{ width: '100%', flex: '1', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px' }}>
              <h3>{vehicle.name}</h3>
              <p>{vehicle.description}</p>
              <div className="image-container" style={{ width: '100%', maxWidth: '495px', height: 'auto' }}>
                <img src={vehicle.imageSrc2} alt={vehicle.name} style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
              </div>
            </div>
            <div style={{ width: '100%', maxWidth: '224px', height: 'auto' }}>
              <img src={imgLogo} style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
            </div>
            <div className='image-card-container' style={{ width: '100%', maxWidth: '500px', height: 'auto', display: 'flex', flexDirection: 'column', gap: '10px', border: '1px solid #ccc', borderRadius: '8px', alignItems: 'center', justifyContent: 'center' }}>
              <button className="nav-arrow left"><img src={leftIcon} alt='<' style={{ height: '27px', width: '27px', objectFit: 'cover' }} /></button>
              <img src={vehicle.imageSrc} alt={vehicle.name} style={{ width: '100%', maxWidth: '348px', height: 'auto', objectFit: 'contain' }} />
              <button className="nav-arrow right"><img src={rightIcon} alt='>' style={{ height: '27px', width: '27px', objectFit: 'cover' }} /></button>
              <button className="interaction-icon" onClick={() => setViewerOpen(true)}><img src={openIcon} alt='+' style={{ height: '27px', width: '27px', objectFit: 'cover' }} /></button>
            </div>
          </div>
        ))}
      </section>

  );
};

const FooterCTA = () => (
<div className="footer-cta" style={{
    position: "relative", width: "100%", height: "auto", padding: "20px 0", display: "flex", alignItems: "center",
    justifyContent: "center", opacity: 1  // Corrected opacity value
  }}>
    <div>
      <div style={{
        width: "100%", maxWidth: "1500px", padding: "20px", boxSizing: "border-box",
      }}>
        <p>
          Empowering to move in a clean, green and quiet environment with safety.
        </p>
      </div>
    </div>
  </div>

);


const TextBanner = () => {
  return (
    <div className="text-banner"style={{marginTop: "50px", marginBottom: "50px"}}>
      <p className='text-[#585858] text-sm md:text-lg w-[90%] md:w-[60%]' style={{ fontWeight: "400", lineHeight: "23px" }}>
        Transvolt works towards development & operation of an integrated and connected ecosystem
        wherein everyone has access to clean, green, and quiet transportation solutions in the B2G,
        B2C and B2B domains. We would like to make the entire ecosystem sustainable and accessible
        for all users – children, pregnant women, senior citizens, specially-abled friends.
      </p>
    </div>
  );
}

const VehicleList = () => (
  <>
    <div className="app">
      <Header />
      <Navbar />
      <div style={{width: "100%", maxWidth: "100%", height:"auto"}}> <img src={img1} style={{width: "100%", height:"auto", objectFit:"cover"}} /></div>      
      <HeaderCTA />
      <div className="parallaxx"></div> {/* Parallax container */}
      <TitleSection />
      <VehicleGrid />
      <FooterCTA />
      <TextBanner />
    </div>
    <Footer />
  </>
);

export default VehicleList;
