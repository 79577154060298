import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import mapIcon from "../Images/Icons/pinMap.jpg";


function MyMap() {
  // Your coordinates: Kamala Mills Compound, 19.0027° N, 72.8286° E
  const position = [19.003343237428346, 72.82757072892996]; // Latitude and Longitude

  const customIcon = L.icon({
    iconUrl: mapIcon,
    iconSize: [50, 50], // Size of the icon
    iconAnchor: [17, 35], // Point of the icon which will correspond to marker's location
    popupAnchor: [0, -35], // Point from which the popup should open relative to the iconAnchor
  });

  return (
    <MapContainer center={position} zoom={15} style={{ height: '50vh', width: '100%', marginTop:"10px", paddingBottom:"200px"}}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker  position={position} icon={customIcon}>
        <Popup>
          Kamala Mills Compound. <br /> Mumbai, India.
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default MyMap;
