// ContactPage.js
import React from 'react';
import { useState } from 'react';

import './ContactPage.css';
import Header from '../header/Header';
import Navbar from '../header/Navbar';
import Footer from '../footer/Footer';
import MyMap from './MyMap';

const ContactPage = () => {
  const [activeTab, setActiveTab] = useState('ENQUIRY');
  const [details, setDetails] = useState({
    name: '',
    contact: '',
    email: '',
    organization: '',
    subject: '',
    message: '',
  });

  const handleSendForm = (e) => {
    e.preventDefault()
    if(activeTab === 'ENQUIRY') {
      // send the details to this email "info@transvolt.in"
    }
    else {
      // send the details to this email "hr@transvolt.in"
    }
  }
  return (
    <>
      <Header />
      <Navbar />
      <img src='/Images/ContactPage/Banner.jpg' className="banner-img" alt="Contact Banner"></img>
      <div className="contact-section justify-center w-full">
        <div className='flex'>

          <div className="bg-black opacity-30 p-10 flex flex-col justify-center">
            <div className="flex items-center gap-x-7">
              <div>
                <img src='/Images/Icons/Email.svg'></img>
              </div>
              <div className='flex flex-col text-white'>
                <h6>MAIL FOR INFORMATION</h6>
                <p>info@transvolt.in</p>
              </div>
            </div>

            <div className="flex items-center gap-x-7">
              <div>
                <img src='/Images/Icons/CALL.svg'></img>
              </div>
              <div className='flex flex-col text-white'>
                <h6>CALL FOR ASSISTANCE</h6>
                <p>+91 8657 526 580</p>
              </div>
            </div>
          </div>

          <div className='carrowHid bg-black opacity-30 pt-24'>
            <img src='/Images/ContactPage/Arrow-Color.svg' />
          </div>
        </div>
        <div className="form-section">
          <div className="tabs">
            <div
              className={`tab ${activeTab === 'ENQUIRY' ? 'active' : ''}`}
              onClick={() => setActiveTab('ENQUIRY')}
            >
              ENQUIRY
            </div>
            <div
              className={`tab ${activeTab === 'CAREER' ? 'active' : ''}`}
              onClick={() => setActiveTab('CAREER')}
            >
              CAREER
            </div>
          </div>
          <form className="contact-form">
            {/* Adjust input names and types as needed */}
            <input onChange={(e) => setDetails({ ...details, name:e.target.value})} type="text" name="name" placeholder="Name" />
            <input onChange={(e) => setDetails({ ...details, contact:e.target.value})} type="text" name="contact" placeholder="Contact" />
            <input onChange={(e) => setDetails({ ...details, email:e.target.value})} type="email" name="email" placeholder="Email" />
            <input onChange={(e) => setDetails({ ...details, organization:e.target.value})} type="text" name="organization" placeholder="Organization Name" />
            <textarea onChange={(e) => setDetails({ ...details, subject:e.target.value})} name="subject" placeholder="Subject"></textarea>
            <textarea onChange={(e) => setDetails({ ...details, message:e.target.value})} name="message" placeholder="Message"></textarea>
            <button onClick={handleSendForm} type="submit">SEND</button>
          </form>
        </div>
      </div>

      <div className="office-addresses-container">
        <h2 className="office-addresses-header">Head Office & Project Office Address</h2>
        <div className="office-cards">
          <div>
          <div className="office-card highlight">
            <h3>HEAD OFFICE</h3>
            <p>Mumbai - Lower Parel</p>
            <address>805, 2nd Floor, Trade Link, Tower A, Kamala Mills, Lower Parel Mumbai 400013</address>
          </div>
          </div>
          <div className="office-card">
            <h3>PROJECT OFFICE</h3>
            <p>Mumbai - Mira Bhayandar</p>
            <address>Namdar Jagannath Nana Shankarseth Bus Depot, Near to RTO Camp office, Yashwant Shankar Matre Road, Ghodbundar Thane - 401107.</address>
          </div>
          <div className="office-card">
            <h3>PROJECT OFFICE</h3>
            <p>Mumbai - Ulhasnagar</p>
            <address>Vitthalwadi Depot, Vitthalwadi, Ulhasnagar, Maharashtra 421306</address>
          </div>
        </div>
      </div>

      <div className='relative'>
        <h3 className='bg-[#4472C4A6] text-white absolute top-0 w-full p-2' style={{zIndex:"1000", textAlign:"center"}}>HEAD OFFICE LOCATION MAP</h3>
        <div style={{height:"300px"}}>
          <MyMap/>
        </div>
      </div>
      <div style={{marginTop: "100px"}}>

      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
