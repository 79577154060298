import axios from 'axios'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import NewsSection from './NewsSection'
import TeamSection from './TeamSection'

const HrControls = () => {

    const email = window.localStorage.getItem("hr")
    const [section, setSection] = useState('news')
    const [news, setNews] = useState([])
    const [team, setTeam] = useState([])
    const [flag, setFlag] = useState(false)

    const logoutHandler = () => {
        window.localStorage.removeItem("hr")
        window.location.href = '/hrlogin'
    }

    useEffect(() => {

        const getDetails = async () => {
            if (section === 'news') {
                await axios.get('http://localhost:4000/news/getallnews')
                    .then(response => {
                        setNews(response.data.msg)
                    })
                    .catch(error => {
                        console.log(error)
                        // toast.error('Error getting news')
                    })
            }

            else if (section === 'team') {
                await axios.get('http://localhost:4000/team/getallmembers')
                    .then(response => {
                        console.log(response)
                        setTeam(response.data.msg)
                    })
                    .catch(error => {
                        // toast.error('Error getting team members')
                    })
            }
        }

        getDetails()

    }, [section, flag])

    if (!email) {
        window.location.href = '/hrlogin'
        return
    }

    return (
        <>
            <div className='flex justify-between p-12 mb-10'>
                <div>
                    <img src='/Images/Logo/black.svg' />
                </div>

                <div>
                    <p className='text-3xl font-semibold text-blue-400'>HR Controls</p>
                </div>
            </div>

            <div className='flex px-96 mb-10'>
                <div onClick={() => setSection('news')} className={`cursor-pointer px-10 py-2 text-center ${section === 'news' && 'bg-blue-200 border-blue-200 rounded-full'}`}>
                    News Section
                </div>

                <div onClick={() => setSection('team')} className={`cursor-pointer p-2 text-center ${section === 'team' && 'bg-blue-200 border-blue-200 rounded-full'}`}>
                    Team members Section
                </div>
            </div>

            <div className='flex justify-center px-32'>
                <button onClick={logoutHandler} className='mt-10 border-red-300 bg-red-300 text-white p-3 rounded-xl'>
                    LogOut
                </button>
            </div>

            {section === 'news' ? <NewsSection news={news} setFlag={setFlag} /> : <TeamSection team={team} setFlag={setFlag} />}
        </>
    )
}

export default HrControls