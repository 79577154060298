import React from 'react';
import Header from '../header/Header';
import Navbar from '../header/Navbar';
import Footer from '../footer/Footer';
import styles from './Governance.css';
import Banner from '../Images/GovernancePage/Banner.jpg';
// GovernancePolicies.js
import { useState } from 'react';


// A single policy component
const Policy = ({ title, content, image }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="policy">
      <div onClick={() => setIsOpen(!isOpen)} className="policy-header">
        <h4 className="policy-title">{title}</h4>
        <div className={`toggle-button ${isOpen ? 'open' : ''}`}>
          {isOpen ? '−' : '+'} {/* Using minus and plus symbols */}
        </div>
      </div>

      {isOpen && (
        <div className="policy-content">
          <div className="policy-text">
            <p>{content}</p>
          </div>
          {image && <img src={image} alt={title} className="policy-image" />}
        </div>
      )}
    </div>
  );
};

// The main component that renders all policies
const Governance = () => {
  // Add all the policies you need in a similar fashion
  const policies = [
    {
      title: "Anti-Bribery Policy",
      content: "Transvolt maintains a robust Anti-Bribery Policy to ensure ethical conduct in all its business dealings. We strictly prohibit the offering, solicitation or acceptance of bribes in any form, including gifts, favors, or monetary incentives, to influence decision-making processes. Our employees are required to adhere to all applicable anti-bribery laws and regulations. We take a zero- tolerance approach to bribery and corruption and are committed to acting professionally, fairly and with integrity in all our business dealings and relationships wherever we operate, and implementing and enforcing effective systems to counter bribery and corruption. By upholding integrity and transparency, we foster a culture of trust and accountability, safeguarding our reputation and promoting fair competition in the marketplace.",
      image: "/Images/GovernancePage/1.png"
      // Add the rest of the policy content and image here
    },
    {
      title: "Code of Conduct for Directors, Key Managerial Personnel and Senior Management",
      content: "Transvolt's Code of Conduct outlines the ethical standards expected from its Board of directors, key managerial personnel and Senior Management. It mandates a complete adherence to legal requirements, integrity, duty of care and confidentiality in all business dealings. Directors and key managers are obligated to act honestly in the Company's best interests, avoiding conflicts of interest and disclosing any potential conflicts promptly. Upholding transparency and ethical behavior fosters trust, ensuring responsible leadership within the organization.",
      image: "/Images/GovernancePage/2.png"
    },
    {
      title: "Code of Conduct for Vendors, Suppliers and Consultants",
      content: "Transvolt’s Code of Conduct for Vendors, Suppliers, and Consultants outlines ethical guidelines paramount to our partnerships. We require adherence to legal standards, integrity in dealings, and respect for intellectual property rights. Transparency, fair competition, and anti-corruption measures are mandatory. Conflicts of interests shall, wherever possible, be avoided that may adversely influence business relationships. Transvolt ensures that its partners respect the internationally recognized human rights, comply with labour standards and elimination of forced labour, child labour and discrimination in the workplace and safeguard the environment Upholding integrity and honesty in business dealings fosters trust and long-term partnerships, ensuring mutual success and sustainability.",
      image: "/Images/GovernancePage/3.png"
    },
    {
      title: "Code of Ethics",
      content: "Transvolt recognizes that it is vital that the behaviour of our employees matches the high intentions and commitment and values. This code expects every employee to conduct business with integrity, in compliance with applicable laws, and in a manner that excludes consideration of direct or indirect personal advantage / gains. It is the individual responsibility of each one of Transvolt’s employees to ensure that all of us are aware of these values, commitments, and procedures, and behave in accordance with the spirit of this code.",
      image: "/Images/GovernancePage/4.png"
    },
    {
      title: "Policy on Prevention, Prohibition and Redressal of Sexual Harassment (POSH)",
      content: "Transvolt is committed to providing a safe and respectful workplace free from all forms of harassment. We, strictly adhere to and follow principals ensuring a safe and respectful environment for all employees as promulgated under Sexual Harassment of Women at Workplace (Prevention, Prohibition, and Redressal) Act, 2013. We have established Internal Complaints Committees (ICCs) to receive and address complaints impartially. Confidentiality and protection are prioritized for complainants, and disciplinary action is taken against offenders. At Transvolt we conduct regular training and awareness programs to educate employees about their rights and responsibilities and are committed to zero tolerance for sexual harassment, gender discrimination fostering a workplace culture where all individuals are treated with dignity and respect.",
      image: "/Images/GovernancePage/5.png"
    },
    {
      title: "Health, Safety And Environment (HSE)",
      content: "We at Transvolt prioritize Health, Safety, and Environment (HSE) across all operations. We comply with regulatory standards and strive for continuous improvement in HSE performance. The objective is to ensure that adequate precautions are taken to avoid accidents, occupational illness, and harmful effects to the environment during execution of work under its contracts. Rigorous risk assessments, safety protocols, and training ensure the well-being of our employees. We minimize environmental impact through sustainable practices, waste reduction, and energy efficiency initiatives. By integrating HSE principles into every aspect of our operations, we uphold our commitment to employee and environment welfare.",
      image: "/Images/GovernancePage/6.png"
    },
    {
      title: "Policy on Quality",
      content: "Transvolt is responsible for the quality of the Work and its quality management system shall be applicable to all aspects. The quality policy of Transvolt’s purchasers, contractors are anchored in our commitment to excellence and customer satisfaction. We procure goods and services that meet or exceed defined quality standards and specifications. Our purchasers ensure supplier compliance with quality management systems and regulatory requirements. We emphasize timely delivery, cost-effectiveness, and sustainability in procurement decisions. We adhere to strict quality control measures at every stage, providing delivery to operational services, ensuring consistency and excellence. Continuous improvement is ingrained in our culture, driving us to exceed customer expectations and uphold our reputation for reliability and excellence.",
      image: "/Images/GovernancePage/7.png"
    },
    {
      title: "Whistle Blower Policy",
      content: "Transvolt’s Whistleblower Policy provides a mechanism for its employees to report concerns regarding unethical behavior, fraud, or violations of company’s policies. Our employees are encouraged to report concerns internally or through designated channels and ensure confidentiality, protection against retaliation, and impartial investigation of reported incidents. The policy prohibits harassment or discrimination against whistleblowers and outlines procedures for handling complaints promptly and transparently. By fostering a culture of integrity and accountability, the Whistleblower Policy strengthens trust, safeguards Transvolt’s values, and promotes a safe and ethical work environment for all.",
      image: "/Images/GovernancePage/8.png"
    }
    // ... other policies
  ];

  return (
    <>
      <Header />
      <Navbar />
      <img src={Banner} className="banner-img" alt="Governance Banner" />
      <div className="bg-[#d4e4ff] text-base md:text-lg py-14 px-10 md:px-44 lg:px-64">
        <p>At Transvolt, safety, ethics, and customer care are our top priorities as we build India's ZERO EMISSION VEHICLE platform. We're committed to upholding the highest standards in every aspect of our work, fostering consistency and pride among our workforce as we serve customers and communities. Together, we're driving change for a better, greener future.</p>
      </div>
      <div className="governance-header">
        <h2>Governance & Policies</h2>
        <img src='/Images/GovernancePage/Arrow-White-Trasnparent.svg' alt="Arrow" className="arrow-img" />
      </div>
      <div className="practices-paragraph">
        <p>At Transvolt, integrity, honesty, and fairness are non-negotiable. We're transparent in our practices, fully compliant with the law, and strive to empower our stakeholders to conduct business with us on equal terms.</p>
      </div>

      <div className='bg-[#eaeaea] py-3'>
        {policies.map((policy, index) => (
          <Policy key={index} title={policy.title} content={policy.content} image={policy.image} />
        ))}
      </div>
      <Footer />

    </>
  );
};

export default Governance;
