import React, { useState } from 'react'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { CiEdit } from 'react-icons/ci'
import '../aboutPage/AboutPage.css'
import imgButton from '../Images/AboutPage/LinkdinButton.svg'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Modal } from 'antd'


const TeamSection = ({ team, setFlag }) => {


  const handleLinkedInClick = (linkedinUrl) => {
    window.open(linkedinUrl, '_blank');
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editMemberId, setMemberId] = useState("")
  const [image, setImage] = useState(null)
  const [formDetails, setFormDetails] = useState({
    name: '',
    role: '',
    imageUrl: '',
    linkedinUrl: ''
  })
  const [editFormDetails, setEditFormDetails] = useState({
    name: '',
    role: '',
    imageUrl: '',
    linkedinUrl: ''
  })

  // const [uploadedUrl, setUploadedUrl] = useState("")

  const [formData, setFormData] = useState(null)

  const handleFileChange = (e) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    formData.append("upload_preset", "rap0jfwa")
    setImage(e.target.files[0])
    setFormData(formData)
  }

  const handelFormSubmission = async (e) => {
    e.preventDefault()
    let uploadedUrl = ""

    // have to call api to upload image and get the url.
    if (image) {
      await axios.post(`https://api.cloudinary.com/v1_1/dipdggpwh/image/upload`, formData)
        .then(response => {
          const match = response.data.url.match(/\/v([\w-]+\/[\w-]+)/)
          uploadedUrl = match[0]
        })
        .catch(error => toast.error(error))
    }

    await axios.post('http://localhost:4000/team/createmember', { ...formDetails, imageUrl: uploadedUrl ? uploadedUrl : "" })
      .then(response => {
        if (response.data.status === true) {
          toast.success(response.data.msg)
          setIsModalOpen(false)
          setFlag(prev => !prev)
        }
      })
      .catch(error => {
        if (error.response.data.msg === 'Team member creation failed' && error.response.data.status === false)
          toast.error(error.response.data.msg)
        else if (Array.isArray(error.response.data.msg)) {
          error.response.data.msg.map(error => (
            toast.error(error)
          ))
        }
      })
  }


  const handelEditFormSubmission = async (e) => {
    e.preventDefault()
    let uploadedUrl = ""
    // have to call api to upload image and get the url.
    if (image) {
      await axios.post(`https://api.cloudinary.com/v1_1/dipdggpwh/image/upload`, formData)
        .then(response => {
          const match = response.data.url.match(/\/v([\w-]+\/[\w-]+)/)
          uploadedUrl = match[0]
        })
        .catch(error => toast.error(error))
    }

    await axios.patch('http://localhost:4000/team/editmember', { ...editFormDetails, imageUrl: uploadedUrl ? uploadedUrl : editFormDetails.imageUrl, editMemberId })
      .then(response => {
        if (response.data.status === true) {
          toast.success(response.data.msg)
          setIsEditModalOpen(false)
          setFlag(prev => !prev)
        }
      })
      .catch(error => {
        if (error.response.data.msg === 'Edit failed' && error.response.data.status === false)
          toast.error(error.response.data.msg)
        else if (Array.isArray(error.response.data.msg)) {
          error.response.data.msg.map(error => (
            toast.error(error)
          ))
        }
        setIsEditModalOpen(false)
      })
  }


  const getSingleMemberDetails = async (memberId) => {
    setMemberId(memberId)
    await axios.post('http://localhost:4000/team/getsinglemember', { memberId })
      .then(response => {
        if (response.data.status) {
          setEditFormDetails({
            name: response.data.msg.name,
            role: response.data.msg.role,
            imageUrl: response.data.msg.imageUrl,
            linkedinUrl: response.data.msg.linkedinUrl
          })
          setIsEditModalOpen(true)
        }
      })
      .catch(error => {
        toast.error(error.response.data.msg)
      })
  }

  const handleDeleteMember = async (memberId) => {
    await axios.delete('http://localhost:4000/team/deletemember', { data: { memberId } })
      .then(response => {
        if (response.data.status) {
          toast.success(response.data.msg)
          setFlag(prev => !prev)
        }
      })
      .catch(error => {
        toast.error(error.response.data.msg)
      })
  }


  return (
    <div className='mt-20 px-32'>

      <div className='mb-20'>
        <button onClick={() => setIsModalOpen(true)} className='flex items-center border-blue-200 bg-blue-200 text-white p-2 rounded-xl'><AiOutlinePlus />Create Member</button>
      </div>

      {
        team.map(member => (
          <div key={member._id} className='mb-16'>
            <div className="team-member-card border-2 border-[#4472C4]" style={{ width: "350px", height: "500px", display: "flex", flexDirection: "column", alignItems: "center", gap: "15px", justifyContent: "center" }}>
              <img
                src={`https://res.cloudinary.com/dipdggpwh/image/upload${member.imageUrl}.png`}
                alt={member.name}
                className="team-member-image"
                style={{ width: "242px", height: "273px" }}
              />
              <div>
                <span className="team-member-name">{member.name}</span>
                <p className="team-member-role">{member.role}</p>
              </div>
              <div>
                <img src={imgButton} onClick={() => handleLinkedInClick(member.linkedinUrl)} alt="LinkedIn" className="linkedin-button" />
              </div>
            </div>
              <CiEdit className='cursor-pointer text-3xl text-blue-500' onClick={() => getSingleMemberDetails(member._id)} />
              <AiOutlineDelete onClick={() => handleDeleteMember(member._id)} className='cursor-pointer text-2xl text-red-500' />
          </div>
        ))
      }


      <>
        <Modal title="Create Member" open={isModalOpen} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
          <form className='mt-12' onSubmit={handelFormSubmission}>
            <div className='flex flex-col'>
              <input className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' type='text' placeholder='Enter the name' onChange={(e) => setFormDetails({ ...formDetails, name: e.target.value })} />
              <input className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' placeholder='Enter the role' onChange={(e) => setFormDetails({ ...formDetails, role: e.target.value })} />
              <div className='mb-3 border-2 border-[#f5f5fa] bg-[#f5f5fa] cursor-pointer rounded-3xl flex justify-center w-64'>
                <label htmlFor="fileInput">
                  <img alt='memberimage' src={image ? URL.createObjectURL(image) : 'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'} className='h-64 w-64 rounded-3xl cursor-pointer' />
                  <input
                    type="file"
                    id="fileInput"
                    className='hidden'
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              <input className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' placeholder='Enter linkedin url' type='text' onChange={(e) => setFormDetails({ ...formDetails, linkedinUrl: e.target.value })} />
              <button className='border-2 border-blue-300 bg-blue-300 rounded-lg p-2 text-white' type='submit'>Create</button>
            </div>
          </form>
        </Modal>
      </>


      <>
        <Modal title="Edit Member" open={isEditModalOpen} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
          <form className='mt-12' onSubmit={handelEditFormSubmission}>
            <div className='flex flex-col'>
              <input defaultValue={editFormDetails.name} className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' type='text' placeholder='Enter the name' onChange={(e) => setEditFormDetails({ ...editFormDetails, name: e.target.value })} />
              <input defaultValue={editFormDetails.role} className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' placeholder='Enter the role' onChange={(e) => setEditFormDetails({ ...editFormDetails, role: e.target.value })} />
              <div className='mb-3 border-2 border-[#f5f5fa] bg-[#f5f5fa] cursor-pointer rounded-3xl flex justify-center w-64'>
                <label htmlFor="fileInput">
                  <img alt='newsimage' src={image ? URL.createObjectURL(image) : editFormDetails.imageUrl ? `https://res.cloudinary.com/dipdggpwh/image/upload${editFormDetails.imageUrl}.png` : 'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'} className='h-64 w-64 rounded-3xl cursor-pointer' />
                  <input
                    type="file"
                    id="fileInput"
                    className='hidden'
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              <input defaultValue={editFormDetails.linkedinUrl} className='border-2 border-gray-100 bg-gray-100 rounded-lg p-2 outline-none mb-4 w-full' placeholder='Enter linkedin url' type='link' onChange={(e) => setEditFormDetails({ ...editFormDetails, linkedinUrl: e.target.value })} />
              <button className='border-2 border-blue-300 bg-blue-300 rounded-lg p-2 text-white' type='submit'>Edit</button>
            </div>
          </form>
        </Modal>
      </>
    </div>
  )
}

export default TeamSection