// App.js or your main component file
import React from 'react';
import './MainContent.css';
import AutoSlider from './AutoSlider';
import Header from '../header/Header';
import Navbar from '../header/Navbar';
import Footer from '../footer/Footer';
import Arrownew from '../Images/ServicesPage/Arrow_new.svg'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import  { useState } from 'react';
import { Link } from 'react-router-dom';





const Headerr = () => (
  <>
    <div className="headerer">
    
    
      <video autoPlay loop muted className="bgVideo"> 
        <source src="../Images/HomePage/BgAanimationn.mp4" type="video/mp4" />
      </video>
      <div style={{zIndex:'1px' ,marginTop:'40px'}}>
        <h1 >Safety and Sustainability</h1>
      </div>
        
    </div>
    <div className='bg-gradient-to-r from-[#01683B] to-[#4472C4] p-5 text-white text-center h-16'>
      <h6 className='blinking-text' style={{fontSize: "15px", marginTop:"-10px"}} >Transvolt commences deployment of 1.5T SCV in Mumbai, Pune, and Delhi.</h6>
    </div>
  </>
);


const FeaturedArea = () => (
  <div>
    <AutoSlider interval={3000} /> {/* Changes every 3 seconds */}
  </div>
);

const WhoWeAre = () => (
  <section className="relative items-center">
    <div className="parallax"></div> {/* Parallax container */}
    <div className='flexxx justify-between items-center bg-[#E0EBFF] md:px-2 py-10'>
      <div className='md:pl-8' style={{paddingLeft:"10%"}}>
        <h2 className='text-[#25509D] mt-3'>WHO WE ARE</h2>
        <div className="contentTrans w-full mt-3" style={{width:"95%"}}>
          <p>Transvolt is more than a business; it's a commitment to combating climate change and improving air quality through zero-emission mobility solutions. We're dedicated to building a green platform that offers ZEROEMISSION services, in alignment with global efforts towards sustainable transportation.</p>
          <p>With a dedicated team and support from top infrastructure funds and financial institutions, we're leading the charge towards sustainable transportation in India and beyond!</p>
        </div>
        <button className="read-more px-4 py-2 mt-1 mb-2 rounded-full">Read more</button>

        
      </div>

      <div className="image-collage">
        <img src="/Images/HomePage/Arrow_Image.png" alt="Description" />
      </div>

    </div>

  </section>
);

const ValueProps = () => (
  <div className="home-page-section">
    <div className="values-section">
      <div className="value-card">
        <div className="icon-we-plan"></div>
        <h3>We Plan</h3>
        <p>Revolutionizing transportation!  We are teaming up with private schools, transportation unions, and government bodies to design bespoke ZERO EMISSION solutions for every community's needs.  Always pushing boundaries with innovative, sleek multimodal public transport options. Constantly innovating to create efficient and attractive multimodal public transport options. Ensuring a safe, efficient, and enjoyable experience for all passengers!! <br></br> <br></br>#zeroemission <br></br> #sustainablefuture</p>
      </div>
      <div className="value-card">
        <div className="icon-we-care"></div>
        <h3>We Care</h3>
        <p>Stepping into passengers' shoes to create top-notch solutions!
          Trust is our foundation - we're forging srtong relationships with public transport authorities for a sustainable future!
          We believe Gender inclusion is critical to the growth of our company and We want to integrate inclusion into the ethos of our business <br></br> <br></br> #EmployeeFirst <br></br> #PassengerFirst <br></br> #TrustAndSustainability <br></br> #EmployeeWellBeing</p>
      </div>
      <div className="value-card">
        <div className="icon-we-commit"></div>
        <h3>We Commit</h3>
        <p>Delivering on promises! At Transvolt, we're committed to safety, quality, and reliability for all our users, transport authorities, and corporate partners.
          Setting the bar high as a beacon of sustainable mobility in every city and region we serve ! <br></br> <br></br> #CommitmentToExcellence <br></br> #SustainableCities</p>
      </div>
    </div>
    <div className="dedication-statement my-3 text-white bg-gradient-to-r from-[#548235] to-[#4472C4] flex justify-center items-center">
      <h6 className='text-xl font-bold w-[100%]' style={{fontSize: '20px'}}>Transvolt is dedicated to building a green platform to provide <span className='font-semibold'>ZERO EMISSION</span> services</h6>
    </div>
  </div>
);


const NewsSection = () => {
  const [sliderRef, setSliderRef] = useState(null);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <FaArrowRightLong className="nextArrow" />,
    prevArrow: <FaArrowLeftLong className="prevArrow" />,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
    ],
  };
  const arr = [
    {
      id: 0,
      image: "/Images/NewsPage/TransvoltPune1.jpg",
      title: "Transvolt starts EV services with Airport operations at Pune Aeromall"
    },
    {
      id: 1,
      image: "/Images/NewsPage/IFCcolab.jpg",
      title: "Transvolt Mobility Collaborates with IFC for $600 million"
    },
    {
      id: 2,
      image: "/Images/NewsPage/uber.jpeg",
      title: "Transvolt Mobility provides EV buses for city-shuttle to UBER"

    },
    {
      id: 3,
      image: "/Images/NewsPage/umt.jpeg",
      title: "Transvolt has commenced e-buses for Ulhasnagar Muncipal Transport"
    },
    {
      id: 4,
      image: "/Images/NewsPage/ekaTruck.jpeg",
      title: "Transvolt Mobility introduces e-trucks, collaborating with EKA"
    },
    {
      id: 5,
      image: "/Images/NewsPage/News-2.png",
      title: "Shuzlan Partners with Transvolt Mobility to offer EV charging infra"
    },
    {
      id: 6,
      image: "/Images/NewsPage/News-1.png",
      title: "EKA Mobility Secures Contract From MBMC To Supply 57 Electric Buses"
    },
    
  ]

 

  return (
    <div className="bg-[#F4F4F4]">
      <div  style={{paddingBottom:"50px", paddingTop:"50px"}}>
        <h2 className='text-center' style={{margin: "20px 20px", color: "#25509D", fontWeight:"600", fontSize: "40px", paddingBottom:"20px"}}>OUR NEWS</h2>
        <div>
          <Slider ref={setSliderRef} {...settings}>
            {arr.map((item) => (
              <div key={item.id} className="news-item">
                <img src={item.image} alt={item.title} className="news-image" />
                <p className='news-title'>{item.title}</p>
                <Link to={{ pathname: '/news', hash: '#11' }}>
                  <button className="read-more-button">Read more</button>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
};

const MainContent = () => (
  <>
    <div className="app">
      <Header />
      <Navbar />
      <Headerr />
      <FeaturedArea />
      <WhoWeAre />
      <ValueProps />
      <NewsSection />
      <Footer />
      {/* Add more components as needed */}
    </div>
  </>
);

export default MainContent;