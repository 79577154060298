// Footer.js
import React from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* About Us Section */}
        <div className="footer-section about-uss">
          <h3>About Us</h3>
          <p>Transvolt Mobility Private Limited, or Transvolt, is more than just a company; it's a vision carefully crafted to lead India's electric mobility revolution.</p>
          <p>With a strong focus on aiding the nation in achieving a 'ZERO EMISSION' transportation sector, we're committed to setting new standards in safety and sustainability while driving business growth and value for all stakeholders.</p>
        </div>


        {/* Contact Section */}
        <div className="footer-section lg:mr-5" >
          <h3>Contact Now</h3>
          <div className='flex flex-col gap-y-3'>
            <div>
              <span>Email: info@transvolt.in</span>
            </div>
            <div>
              <img src='Images/Icons/CALL.svg'></img>
              <span>Phone: +91 8657 526 580</span>
            </div>
            <div>
              <img src='Images/Icons/Address.svg'></img>
              <span>805, 2nd Floor, Trade Link,Tower A, Kamala Mills, Lower Parel, Mumbai 400013.</span>
            </div>
          </div>
          <div className="social-icons" style={{paddingLeft:"70px"}}>
            <a><img src='Images/Icons/Twitter.svg'></img></a>
            <a><img src='Images/Icons/Facebook.svg'></img></a>
            <a href="https://www.linkedin.com/company/transvolt-mobility-pvt-ltd/"><img src='Images/Icons/Linkedin.svg'></img></a>
          </div>
        </div>

        {/* News Section */}
        <div className="footer-section">
          <h3>News</h3>
          <a href="/news#article1" className='p-2 rounded-xl border-2 border-[#f2f2f2]'>Started commercial operation at MBMT</a>
          <a href="/news#article2" className='p-2 rounded-xl border-2 border-[#f2f2f2]'>Shuzlan Energy Partners with Transvolt</a>
          <a href="/news" className="show-all p-2 rounded-xl border-2 border-[#f2f2f2] w-full text-center">Show all News</a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2023 Transvolt. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
