// Header.js
import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="header">
      {/* <div className="logo">
        <img src='images/Logo/black.svg'></img>
      </div> */}
      {/* <nav className="navigation">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/services">Services</a>
        <a href="/governance">Governance</a>
        <a href="/news">News</a>
        <a href="/contact">Contact</a>
      </nav> */}
      <div className='socials'>
        <img src='Images/Icons/Twitter.svg'></img>
        <img src='Images/Icons/Facebook.svg'></img>
        <img src='Images/Icons/Linkedin.svg'></img>
      </div>
      <div className="contact-info">
        <a href="mailto:info@transvolt.in">info@transvolt.in</a>
        <a href="tel:+9191657526580">+91 8657 526 580</a>
      </div>
    </header>
  );
}

export default Header;
