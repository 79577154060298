
// NewsSection.js
import React, { useEffect, useState } from 'react';
import './NewsSection.css';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import Navbar from '../header/Navbar';
import NewsCard from './NewsCard';
import axios from 'axios';
import toast from 'react-hot-toast';

// Example data structure for news items
const newsItems = [
  // {
  //   id: 1,
  //   imageUrl: "/Images/NewsPage/IFCcolab.jpg",
  //   altText: "Collaboration with IFC",
  //   headline: "Transvolt Mobility signs Collaboration with IFC for over $600 million",
  //   content: "Transvolt Mobility signs Collaboration Agreement with IFC for techno-commercial evaluation of EV projects covering capex outlay of over $600 million, across India and 8000 Electric Vehicles.\n\n ▪ Transvolt is an OEM agnostic EV Platform, owning & operating EVs in various segments for diverse customers in both the government & private sector. \n\n ▪ Transvolt has partnered with IFC to undertake a techno-commercial evaluation of EV projects, across multiple transportation segments – using EV buses, trucks, and SCVs, which are sourced from multiple OEMs and service a variety of off-takers, which could subsequently be considered for IFC financing."
  // },
  {
    id: 3,
    imageUrl: "/Images/NewsPage/umt.jpeg",
    altText: "UMT",
    headline: "Transvolt has commenced e-buses for Ulhasnagar Muncipal Transport",
    content: "Transvolt Mobility has commenced e-buses for the city of Ulhasnagar, showcasing its commitment to promoting sustainable transportation solutions for the upcoimg satellite towns of Mumbai region. . With infrastructure in place for e-bus operations, including charging and maintenance facilities, the company ensures seamless integration and operation. Comprehensive training programs equip pilots and maintenance staff with the necessary skills to operate and maintain e-buses efficiently. Through this integrated approach, Transvolt Mobility aims to deliver reliable, eco-friendly transportation solutions while upholding operational excellence standards in Ulhasnagar and beyond."
  },
  {
    id: 4,
    imageUrl: "/Images/NewsPage/ekaTruck.jpeg",
    altText: "EKA EvTruck",
    headline: "Transvolt Mobility introduces e-trucks",
    content: "In their latest endeavor, Transvolt Mobility introduces e-trucks, marking a significant project aimed at transforming freight transportation in key cities such as Mumbai, Pune, and Delhi. As the first step, Transvolt has launched the fleet of electric Small Commercial Vehicles (SCVs). These SCVs, boasting a 1.5-ton capacity are deployed through our partners and deployed to serve multiple clients in varies sectors such as pharmaceutical, piant, general cargo etc catering the last-mile and first-mile requirements. , With sustainability as a driving force, this initiative underscores Transvolt Mobility's commitment to pioneering innovative solutions in urban mobility, thereby reducing carbon emissions and promoting cleaner, more efficient logistical operations. In addition to lowering carbon emissions, these e-trucks also address the challenges of noise pollution commonly associated with traditional diesel-powered trucks. As a result, they contribute to fostering quieter and more pleasant urban environments, enhancing overall quality of life for residents."
  },
  {
    id: 5,
    imageUrl: "/Images/NewsPage/News-2.png",
    altText: "Shuzlan",
    headline: "Shuzlan Energy Partners with Transvolt Mobility to offer EV charging infrastructure for 1,000 buses",
    content: "In a monumental move towards sustainable transportation, Shuzlan Energy proudly announces its partnership with Transvolt Mobility to deploy EV Charging Point Operator (CPO) infrastructure for 1,000 buses across India."
  },
  {
    id: 2,
    imageUrl: "/Images/NewsPage/News-1.png",
    altText: "EKA Mobility",
    headline: "EKA & Transvolt Mobility Secures Contract From MBMT To Supply 57 Electric Buses",
    content: "Transvolt Mobility marks it’s entry into the electric bus segment as a responsible operator with “Own & Operate” model. As the owner and operator of electric vehicles, strategically implementing eco-friendly transportation solutions across Mumbai and its suburban regions. We intend to target key areas of Mumbai and its suburban in the first phase, Transvolt has commenced operations in Mira Bhayandar, with the aim of fostering sustainable mobility and combating urban pollution of providing e- buses. As suppliers, Transvolt Mobility guarantees the seamless availability of essential components and parts crucial for the uninterrupted operation of these e buses. Moreover, Transvolt Mobility extends its commitment to service by providing well trained pilots to ensure safe and efficient operation of their electric vehicles. These pilots undergo comprehensive training to handle the unique requirements of electric buses, emphasizing not only technical proficiency but also a dedication to eco-friendly and sustainable transportation practices. Transvolt Mobility enhances the quality of service provided to the people, prioritizing safety, reliability, and environmental responsibility in their operations. Our substantial investment in building robust infrastructure including state-of-the art charging infrastructure, enables efficient charging and continual operation of e-buses, harnessing power from the grid. Through partnerships with townships and civic bodies, we aim to smoothly incorporate e-buses into the current transport system, creating a more sustainable and inclusive urban environment in Mumbai and its suburbs."
  },
];

const NewsSection = () => {

  const [fetchedNews, setFetchedNews] = useState([])
  const handleClickLinkedin = () => {
    window.open("https://www.linkedin.com/company/transvolt-mobility-pvt-ltd/", "_blank");
  };

  const handleVisitClick = () => {
    window.location.href = '/Images/Transvoltpr.pdf';
  };

  useEffect(() => {
    const getNews = async () => {
      await axios.get('http://localhost:4000/news/getallnews')
        .then(response => {
          setFetchedNews(response.data.msg)
        })
        .catch(error => {
          console.log(error)
          // toast.error('Error getting news')
        })
    }

    getNews()
  }, [])

  return (
    <>
      <Header />
      <Navbar />
      <img src='/Images/NewsPage/Banner.jpg' className="banner-img" alt="News Banner"></img>
      <div className="news-section-container">
        <h6 className="news-section-header">
          <button className="news-update-btn">
            Transvolt News & Update
          </button>
        </h6>

        {fetchedNews.map((item) => (
          <div id={item.id} className="news-card flex flex-col p-3 mb-5" key={item.id}>
            <NewsCard item={item} handleClickLinkedin={handleClickLinkedin} />
          </div>
        ))}

        <div id={11} className="news-card flex flex-col p-3 mb-5">
          <div className='relative flex h-[50%] gap-x-7 items-center'>
            <img src="/Images/NewsPage/TransvoltPune1.jpg" alt="Uber Tieup" className="news-image" />
            <img src="/Images/NewsPage/TransvoltPune2.jpg" alt="Uber Tieup" className="news-image" />
            <img className='arrow-image' src='/Images/NewsPage/Arrow-Color-Transparent.svg' alt="Arrow" />
            <h4 className='text-[#25509D]'>Transvolt Mobility starts EV services with Airport operations at Pune Aeromall            </h4>
            <button className="visit-button" onClick={handleClickLinkedin}>Read</button>
          </div>
          <div className="mt-7">
            <p>Transvolt is elated to announce the debut of our cutting-edge 9-meter AC shuttle bus, now operational at Pune International Airport in partnership with EKA. This innovative semi-low floor bus at Aeromall Pune is crafted to provide seamless and comfortable passenger transfers, ensuring an enhanced travel experience for everyone. Dedicated to sustainability, this initiative significantly reduces carbon emissions, establishing a new benchmark for eco-friendly passenger transport. It is a significant stride towards our unwavering commitment to sustainable solutions and a greener tomorrow.</p>
          </div>
        </div>

        <div id={11} className="news-card flex flex-col p-3 mb-5">
          <div className='relative flex h-[50%] gap-x-7 items-center'>
            <img src="/Images/NewsPage/IFCcolab.jpg" alt={"IFC Colaboration"} className="news-image" />
            <img className='arrow-image' src='/Images/NewsPage/Arrow-Color-Transparent.svg' alt="Arrow" />
            <h4 className='text-[#25509D]'>{"Transvolt Mobility signs Collaboration with IFC for over $600 million"}</h4>
            {/* Updated button with onClick handler */}
            <button className="visit-button" onClick={handleVisitClick}>View</button>
          </div>
          <div className="mt-7">
            <p>{"Transvolt Mobility signs Collaboration Agreement with IFC for techno-commercial evaluation of EV projects covering capex outlay of over $600 million, across India and 8000 Electric Vehicles.\n\n ▪ Transvolt is an OEM agnostic EV Platform, owning & operating EVs in various segments for diverse customers in both the government & private sector. \n\n ▪ Transvolt has partnered with IFC to undertake a techno-commercial evaluation of EV projects, across multiple transportation segments – using EV buses, trucks, and SCVs, which are sourced from multiple OEMs and service a variety of off-takers, which could subsequently be considered for IFC financing."}</p>
          </div>
        </div>

        <div id={11} className="news-card flex flex-col p-3 mb-5">
          <div className='relative flex h-[50%] gap-x-7 items-center'>
            <img src="/Images/NewsPage/uber.jpeg" alt="Uber Tieup" className="news-image" />
            <video width="320" height="240" controls className="news-video" poster="/Images/NewsPage/uberBanner.jpeg">
              <source src="/Images/NewsPage/uberVideo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img className='arrow-image' src='/Images/NewsPage/Arrow-Color-Transparent.svg' alt="Arrow" />
            <h4 className='text-[#25509D]'>Transvolt Mobility provides electric buses for city-shuttle segment to UBER</h4>
            <button className="visit-button" onClick={handleClickLinkedin}>Read</button>
          </div>
          <div className="mt-7">
            <p>In line with Uber's objectives of clean and green transportation solutions, Transvolt Mobility provides electric buses for city-shuttle segment <b> in New Delhi </b>. This initiative signifies a pivotal shift towards cleaner and more intelligent transportation solutions. With robust operation and infrastructure in place, including strategically located charging stations and maintenance facilities, these e-buses represent a significant advancement in sustainable mobility. This endeavor marks a noteworthy milestone in Transvolt's ongoing mission to redefine urban mobility and cultivate a greener, more sustainable future, particularly through its contribution to Uber's commitment to environmental responsibility.</p>
          </div>
        </div>

        {newsItems.map((item) => (
          <div id={item.id} className="news-card flex flex-col p-3 mb-5" key={item.id}>
            <NewsCard item={item} handleClickLinkedin={handleClickLinkedin} />
          </div>
        ))}

      </div>
      <Footer />
    </>
  );
};


export default NewsSection;
